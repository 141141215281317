import axios from 'axios';
import pathToRegex from 'path-to-regexp';
import queryString from 'query-string';
import React from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { fetchAssessmentsData } from '../../actions/getAssessments';
import { fetchUserData } from '../../actions/userPersonalInfo';
import { fetchVideoAssessmentData } from '../../actions/videoAssessmentsAction';
import Loader from '../../components/Loader/Loader';
import PageWrapper from '../../components/PageWrapper';
import Progress from '../../components/TestProgressBar';
import { BASE_URL, PATH } from '../../constants/variables';
import { QuestionType } from '../../models/assessments';
import setToken from '../../setToken';
import routes from '../../utils/routes';
import { withTranslation } from 'react-i18next';
import { CONTENT_DIRECTION } from '../../constants/contentDirection';
import * as moment from 'moment';
import { Box, Modal, Typography } from '@material-ui/core';

class AssessmentsParticularVacancy extends React.Component {
  state = {
    ModalBrowser: false,
    ModalPermission: false,
  };
  componentDidMount() {
    const {
      fetchUserData,
      fetchAssessmentsData,
      history,
      match,
      userInfo,
    } = this.props;
    const token = localStorage.getItem('token');
    if (token) {
      setToken(token);
      if (!userInfo) {
        fetchUserData();
      }
      fetchAssessmentsData(match.params.id);
    } else if (!token) {
      history.push('/login');
    }
  }
  showPermissionModal = () => {
    this.setState({ ModalPermission: true });
  };
  showBrowserModal = () => {
    this.setState({ ModalBrowser: true });
  };
  hideModals = () => {
    this.setState({ ModalPermission: false, ModalBrowser: false });
  };

  runChecks = async () => {
    var isChromium = window.chrome;
    var winNav = window.navigator;
    var vendorName = winNav.vendor;
    var isOpera = typeof window.opr !== 'undefined';
    var isIEedge = winNav.userAgent.indexOf('Edg') > -1;
    var isIOSChrome = winNav.userAgent.match('CriOS');
    var isSupportedBrowser =
      !isIOSChrome &&
      isChromium !== null &&
      typeof isChromium !== 'undefined' &&
      vendorName === 'Google Inc.' &&
      isOpera === false &&
      isIEedge === false;
    if (!isSupportedBrowser) {
      this.showBrowserModal();
      return false;
    }
    let mediaChecked = await navigator.mediaDevices
      .getUserMedia({
        video: true,
        audio: true,
      })
      .catch(() => {
        this.showPermissionModal();
        return false;
      });
    if (!mediaChecked) {
      return false;
    }
    return true;
  };

  getAssessmentDuration(assessment) {
    try {
      let duration = moment(assessment.duration, 'HH:mm:ss').get('minutes');
      return this.props.t('minutes', { time: duration });
    } catch {
      return '';
    }
  }

  handleStartTestV2 = (vacancy) => {
    const navigateToInfo = () => {
      this.props.history.push(`/assessments/${vacancy.id}/info`);
    };
    switch (vacancy.test.type.id) {
      case QuestionType.Personality:
      case QuestionType.SituationalJudgement:
        navigateToInfo();
        break;
      case QuestionType.CareerDrivers:
        axios
          .put(`${BASE_URL}${PATH}assessments/${vacancy.id}/start/`)
          .then((res) => {
            if (res.data.success) {
              this.props.history.push(
                `/career-drivers/${vacancy.id}?test=${vacancy.test.id}`,
              );
            }
          });
        break;
      case QuestionType.Video:
      case QuestionType.ProfileQuestionnaire:
      case QuestionType.SurveyAssessment:
      case QuestionType.TechnicalAssessment:
        navigateToInfo();
        break;
      case QuestionType.Games:
        axios
          .put(`${BASE_URL}${PATH}assessments/${vacancy.id}/start/`)
          .then((res) => {
            if (res.data.success) {
              this.props.history.push(
                `/game-assessments/${vacancy.id}`, //?test=${vacancy.test.id}`
              );
            }
          });
        break;
      case QuestionType.VideoAssessment:
        this.runChecks().then((check) => {
          if (check) {
            navigateToInfo();
          }
        });
        break;
      default:
        navigateToInfo();
        break;
    }
  };
  openNewTab = (videoAssessment) => {
    // const { videoAssessment } = this.props;
    window.open(videoAssessment, '_blank');
    window.location.href = '/dashboard';
  };
  handleStartTest = (vacancy) => {
    switch (vacancy.test.type.id) {
      case QuestionType.Personality:
        if (vacancy.status === 1) {
          this.props.history.push(
            `/personality-assessment/${vacancy.id}/?test=${vacancy.test.id}&uuid=${this.props.userInfo.id}`,
          );
        } else if (vacancy.status === 4) {
          this.props.history.push(
            `/personality-assessment/${vacancy.id}/tie?test=${vacancy.test.id}&uuid=${this.props.userInfo.id}`,
          );
        }
        break;
      case QuestionType.SituationalJudgement:
        this.props.history.push(
          `/sjt-assessment/${vacancy.id}?test=${vacancy.test.id}`,
        );
        break;
      case QuestionType.CareerDrivers:
        this.props.history.push(
          `/career-drivers/${vacancy.id}?test=${vacancy.test.id}`,
        );
        break;
      case QuestionType.Video:
        // ? what's the relationship between video and personality-assessment
        this.props.history.push(
          `/personality-assessment/${vacancy.id}/tie?test=${vacancy.test.id}`,
        );
        break;
      case QuestionType.ProfileQuestionnaire:
      case QuestionType.SurveyAssessment:
      case QuestionType.TechnicalAssessment:
        this.props.history.push(
          `/questionnaire-assessment/${vacancy.id}?test=${vacancy.test.id}`,
        );
        break;
      case QuestionType.Games:
        this.props.history.push(`/game-assessments/${vacancy.id}`);
        break;
      case QuestionType.VideoAssessment:
        this.runChecks().then((check) => {
          if (check) {
            this.props.history.push({
              pathname: pathToRegex.compile(routes.videoAssessment)({
                id: vacancy.id,
              }),
              search: queryString.stringify({
                test: vacancy.test.id,
                application: this.props.match.params.id,
              }),
            });
          }
        });
        break;
      default:
        break;
    }
  };

  render() {
    const {
      isLoading,
      userInfo,
      assessments,
      assessmentsLoading,
      error,
      t,
    } = this.props;
    if (isLoading || !userInfo || assessmentsLoading) {
      return <Loader />;
    }
    if (error) {
      return <Redirect to="/404" />;
    }

    const direction = localStorage.getItem('contentDirection');

    const notStarted = !assessments.list.knocked
      ? assessments.list.current.filter((assItem) => assItem.status === 0)
      : [];
    const pending = !assessments.list.knocked
      ? assessments.list.current.filter(
          (assItem) => assItem.status === 1 || assItem.status === 4,
        )
      : [];
    const { completed } = assessments.list;

    return (
      <PageWrapper
        isDashboardHeader
        history={this.props.history}
        match={this.props.match}
        isDashboardFooter
      >
        <div className="d-flex flex-column">
          <Link to="/dashboard" className="goVacancy text-align-start">
            <img
              src="/assets/img/arrow-right.svg"
              className={`arrowRight ${
                direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
              }`}
              alt="arrow"
            />
            <span>{t('Back to Dashboard')}</span>
          </Link>
          <Modal
            open={this.state.ModalPermission || this.state.ModalBrowser}
            onClose={() => {
              this.hideModals();
            }}
          >
            <Box
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                maxWidth: 500,
                backgroundColor: '#fff',
                borderRadius: 20,
                padding: 20,
              }}
            >
              <div
                style={{
                  display: 'flex',
                }}
              >
                <div>
                  <img
                    alt="modal icon"
                    src={
                      this.state.ModalBrowser
                        ? 'https://upload.wikimedia.org/wikipedia/commons/8/87/Google_Chrome_icon_%282011%29.png'
                        : '/assets/img/permissions.png'
                    }
                    style={{ width: 150 }}
                  />
                </div>
                <div
                  style={{
                    padding:
                      direction === CONTENT_DIRECTION.RTL
                        ? '20px 20px 0px 0px'
                        : '20px 0px 0px 20px',
                  }}
                >
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    style={{
                      marginBottom: 10,
                      textAlign:
                        direction === CONTENT_DIRECTION.RTL ? 'right' : 'left',
                    }}
                  >
                    {this.state.ModalPermission
                      ? t('Permissions')
                      : t('Browser')}
                  </Typography>
                  <Typography
                    id="modal-modal-description"
                    sx={{ mt: 2 }}
                    style={{
                      textAlign:
                        direction === CONTENT_DIRECTION.RTL ? 'right' : 'left',
                    }}
                  >
                    {this.state.ModalPermission
                      ? t('Permissions_block')
                      : t('Browser_block')}
                  </Typography>
                  <div
                    style={{
                      display: 'flex',
                    }}
                  >
                    <div style={{ flex: 1 }} />
                    <button
                      style={{
                        width: '164px',
                      }}
                      onClick={() => {
                        this.hideModals();
                      }}
                      className="tr8s-button green assessmentBtnNext"
                    >
                      {t('Agree')}
                    </button>
                  </div>
                </div>
              </div>
            </Box>
          </Modal>
          <div className="main-content-wrapper w-100">
            <p className="test-result-title-assessments text-align-start">
              {assessments.applicationInfo.job.title}
            </p>
            <div className="progressStatus mb-4">
              <Progress
                current={completed.length}
                all={pending.length + completed.length + notStarted.length}
              />
            </div>

            {!!notStarted.length && (
              <p className="test-result-subtitle text-align-start">
                {t('Not Started')}
              </p>
            )}

            <div className="whitebox__card-row mb-0">
              {notStarted.map((vac) => {
                return (
                  <div
                    key={vac.id}
                    className={`whitebox__card-wrapper ${
                      direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
                    }`}
                  >
                    <div className="whitebox__card jobCard">
                      <div className="d-flex align-items-center">
                        <div
                          className="whitebox__card-icon"
                          dangerouslySetInnerHTML={{
                            __html: vac.test.icon_svg,
                          }}
                        ></div>
                        <span
                          className={`titleForWebDev ${
                            direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
                          }`}
                          dangerouslySetInnerHTML={{
                            __html: `${t(vac.test.title)}`,
                          }}
                        ></span>
                      </div>
                      <div className="bottmWrapper">
                        <div className="bottmWrapperStatus">
                          <div className="text-align-start">
                            <p className="titleAssessments">
                              {t('Estimated time')}
                            </p>
                            <p style={{ fontSize: '11px' }}>
                              {this.getAssessmentDuration(vac)}
                            </p>
                          </div>
                          <div className="text-align-start">
                            <p className="titleAssessments">
                              {t('Progress Status')}
                            </p>
                            <p
                              className="vacancyStatus"
                              style={{
                                color: '#dc6a54',
                                fontSize: '11px',
                              }}
                            >
                              {t('Not Started')}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex">
                        <button
                          style={{
                            margin: '0 0 0 3px',
                            fontSize: '11px',
                            padding: '1px 28px',
                          }}
                          className="test-result-block-button"
                          onClick={() => this.handleStartTestV2(vac)}
                        >
                          {t('Start')}
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            {!!pending.length && (
              <p className="test-result-subtitle text-align-start">
                {t('Pending')}
              </p>
            )}
            <div className="whitebox__card-row mb-0">
              {pending.map((vac) => {
                return (
                  <div
                    key={vac.id}
                    className={`whitebox__card-wrapper ${
                      direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
                    }`}
                  >
                    <div className="whitebox__card jobCard">
                      <div className="d-flex align-items-center">
                        <div
                          className="whitebox__card-icon"
                          dangerouslySetInnerHTML={{
                            __html: vac.test.icon_svg,
                          }}
                        ></div>
                        <span
                          className={`titleForWebDev ${
                            direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
                          }`}
                          dangerouslySetInnerHTML={{
                            __html: `${t(vac.test.title)}`,
                          }}
                        ></span>
                      </div>
                      <div className="bottmWrapper">
                        <div className="bottmWrapperStatus">
                          <div className="text-align-start">
                            <p className="titleAssessments">
                              {t('Estimated time')}
                            </p>
                            <p style={{ fontSize: '11px' }}>
                              {this.getAssessmentDuration(vac)}
                            </p>
                          </div>
                          <div className="text-align-start">
                            <p className="titleAssessments">
                              {t('Progress Status')}
                            </p>
                            <p
                              className="vacancyStatus"
                              style={{
                                color: '#27A4A1',
                                fontSize: '11px',
                              }}
                            >
                              {t('Pending')}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex">
                        <button
                          style={{
                            margin: '0 0 0 3px',
                            fontSize: '11px',
                            padding: '1px 28px',
                          }}
                          className="test-result-block-button"
                          onClick={() => this.handleStartTest(vac)}
                        >
                          {t('Continue')}
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            {!!completed.length ? (
              <p className="test-result-subtitle text-align-start">
                {t('Completed')}
              </p>
            ) : null}
            <div className="whitebox__card-row mb-5">
              {completed.map((vac) => {
                this.application_id = vac.application_id;
                return (
                  <div
                    key={vac.id}
                    className={`whitebox__card-wrapper ${
                      direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
                    }`}
                  >
                    <div className="whitebox__card completedCard">
                      <div className="d-flex align-items-center">
                        <div
                          className="whitebox__card-icon"
                          dangerouslySetInnerHTML={{
                            __html: vac.test.icon_svg,
                          }}
                        ></div>
                        <span
                          className={`titleForWebDev ${
                            direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
                          }`}
                          dangerouslySetInnerHTML={{
                            __html: `${t(vac.test.title)}`,
                          }}
                        ></span>
                      </div>
                      <div className="bottmWrapper">
                        <div className="bottmWrapperStatus">
                          <div className="text-align-start">
                            <p className="titleAssessments">
                              {t('Estimated time')}
                            </p>
                            <p style={{ fontSize: '11px' }}>
                              {this.getAssessmentDuration(vac)}
                            </p>
                          </div>
                          <div className="text-align-start">
                            <p className="titleAssessments">
                              {t('Progress Status')}
                            </p>
                            <p style={{ fontSize: '11px' }}>{t('Completed')}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </PageWrapper>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userInfo: state.authUser.userInfo,
    isLoading: state.authUser.isLoading,
    isLoadingVacancies: state.vacanciesReducer.isLoading,
    vacancies: state.vacanciesReducer.vacancies,
    assessments: state.assessmentsReduces.assessments,
    assessmentsLoading: state.assessmentsReduces.isLoading,
    error: state.assessmentsReduces.error,
  };
};

export default withTranslation()(
  connect(mapStateToProps, {
    fetchUserData,
    fetchAssessmentsData,
    fetchVideoAssessmentData,
  })(AssessmentsParticularVacancy),
);
